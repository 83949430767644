/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CloudProcessingStatus,
  DiagnosticDashboardQuery,
} from "generated/graphql";
import {
  graphql,
  GraphQLMockedContext,
  GraphQLMockedRequest,
  GraphQLRequestParsedResult,
  RequestHandler,
} from "msw";

const jiraHandlers: RequestHandler<
  GraphQLMockedRequest<Record<string, any>>,
  GraphQLMockedContext<any>,
  GraphQLRequestParsedResult<Record<string, any>>,
  GraphQLMockedRequest<any>,
  any
>[] = [
  graphql.query("Dashboard", (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.data({
        aggregators: [
          {
            label: "Annual Cost",
            value: 48345678.9,
            variant: "money",
          },
          {
            label: "Savings Opportunity",
            value: 12345678.9,
            variant: "money",
          },
          {
            label: "Issue Count",
            value: 45678,
            variant: "current",
          },
        ],
        opCountStatus: [
          {
            status: "pending",
            amount: 10636565.33,
            count: 36556,
          },
          {
            status: "scheduled",
            amount: 22551.13,
            count: 2551,
          },
          {
            status: "in progress",
            amount: 1551.13,
            count: 13551,
          },
          { status: "failed", amount: 0, count: 0 },
          { status: "rejected", amount: 3854, count: 2804 },
          {
            status: "success",
            amount: 1500200.9,
            count: 90299.9,
          },
        ].map((op) => ({ ...op, ratio: op.amount / 12345678.9 })),
      })
    );
  }),
];
if (process.env.NODE_ENV === "test") {
  jiraHandlers.push(
    graphql.query<DiagnosticDashboardQuery>(
      "DiagnosticDashboard",
      (req, res, ctx) => {
        return res(
          ctx.status(200),
          ctx.data({
            diagnostics: {
              opportunitiesCount: 44,
              services: [
                {
                  cost: 1757.18,
                  savings: 739.58,
                  awsService: { shortName: "EC2" },
                },
                {
                  cost: 197.1,
                  savings: 197.1,
                  awsService: { shortName: "Elastic Load Balancing v2" },
                },
                {
                  cost: 136.66,
                  savings: 129.83,
                  awsService: { shortName: "DynamoDB" },
                },
              ],
              top: [
                {
                  region: "us-east-1",
                  savings: 197.1,
                  resourceId:
                    "loadbalancer/app/CO-Ji-LoadB-1QPK5IV4PYLB9/f300f5af6b8a3782",
                  playbook: {
                    titleDiagnostics: "Idle ELB",
                    details: {
                      description:
                        "This ELB is idle and should be considered for deletion.",
                    },
                  },
                },
                {
                  region: "us-east-1",
                  savings: 120.0,
                  resourceId: "vol-0b746771acac8f8f6",
                  playbook: {
                    titleDiagnostics: "Unattached EBS volume",
                    details: {
                      description:
                        "This EBS volume is not attached to and EC2 instance, and should be deleted.",
                    },
                  },
                },
                {
                  region: "us-east-1",
                  savings: 108.19,
                  resourceId: "terraform-state-lock",
                  playbook: {
                    titleDiagnostics: "Underutilized DynamoDB table writes",
                    details: {
                      description:
                        "We recommended that you resize the provisioned write capacity and use DynamoDB Auto Scaling to manage the throughput capacity based on workload.",
                    },
                  },
                },
              ],
            },
            process: {
              start: "2021-04-07T17:48:50",
              end: "2021-04-08T17:11:49",
              analyzed: 100.0,
            },
          })
        );
      }
    )
  );
}

export default jiraHandlers;
